// @flow

import "./checkbox_button.css";
import Markdown from "./markdown";
import classNames from "classnames";
import { PureComponent } from "react";
import * as React from "react";

type Props = {
    disabled: boolean,
    selected: boolean,
    text: string,
    type: string,
    className: string,
    renderer: string,
    onClick: () => void,
};

class CheckboxButton extends PureComponent<Props> {
    static defaultProps: {
        type: string,
    };

    render(): React.Node {
        return (
            <button
                type="button"
                onClick={this.props.onClick}
                disabled={this.props.disabled && !this.props.selected}
                aria-selected={this.props.selected}
                aria-label={this.props.text}
                className={classNames("checkbox-button", this.props.className, {
                    "checkbox-button--selected": this.props.selected,
                })}
            >
                <div className="checkbox-button--checkbox">
                    <div className="checkbox-button--circle" />
                </div>
                {this.props.renderer === "markdown" ? (
                    <Markdown
                        className="checkbox-button--text"
                        inline={true}
                        text={this.props.text}
                    />
                ) : (
                    <div className="checkbox-button--text">{this.props.text}</div>
                )}
            </button>
        );
    }
}

CheckboxButton.displayName = "CheckboxButton";
CheckboxButton.defaultProps = {
    type: "button",
};

export default CheckboxButton;
