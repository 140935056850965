// @flow

import "./markdown.css";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { PureComponent } from "react";
import * as React from "react";
import classNames from "classnames";

type Props = {
    text: string,
    inline: boolean,
    parseInline: boolean,
    className: string,
};

function markdownHelper(markdownText, parseInline) {
    const options = {
        ADD_ATTR: ["target"],
    };
    if (parseInline) {
        return { __html: DOMPurify.sanitize(marked.parseInline(markdownText), options) };
    } else {
        return { __html: DOMPurify.sanitize(marked.parse(markdownText), options) };
    }
}

class Markdown extends PureComponent<Props> {
    static defaultProps: {
        inline: boolean,
        parseInline: boolean,
        className: string,
    };

    render(): React.Node {
        const paragraphs = this.props.text.split("\n");
        return (
            <div
                className={classNames("markdown", this.props.className, {
                    "markdown-inline": this.props.inline,
                })}
            >
                {paragraphs.map((para, idx) => (
                    <div
                        key={`markdown-para-${idx}`}
                        dangerouslySetInnerHTML={markdownHelper(para, this.props.parseInline)}
                    />
                ))}
            </div>
        );
    }
}

Markdown.displayName = "Markdown";
Markdown.defaultProps = {
    inline: false,
    parseInline: false,
    className: "",
};

export default Markdown;
