// @flow

import "./header.css";
import { Component } from "react";
import * as React from "react";
import { withTranslation } from "react-i18next";

type OwnProps = {
    imageUrl: string,
    teamName: string,
    phoneNumber: string,
    onClose: () => void,
};
type Props = OwnProps & {
    t: (string) => string,
};

class Header extends Component<Props> {
    render() {
        const { t } = this.props;
        const phoneNumberHref = `tel:${this.props.phoneNumber.replace(/[^0-9]/g, "")}`;
        return (
            <div className="header">
                <div className="header--padded">
                    <div className="header--badge">
                        <div className="header--badge-text" aria-hidden="true">
                            {this.props.teamName.substr(0, 1)}
                        </div>
                    </div>
                    <button
                        data-test="chat-close"
                        aria-label={t("close")}
                        className="header--close-button"
                        onClick={this.props.onClose}
                    >
                        ✕
                    </button>
                    <div className="header--middle">
                        <div className="header--team-name" id="chat-team-name">
                            <h2 className="no-padding font-weight-normal font-size-20">
                                {this.props.teamName}
                            </h2>
                        </div>
                        {this.props.phoneNumber && (
                            <div>
                                <a
                                    className="header--phone-number"
                                    href={phoneNumberHref}
                                    aria-label={
                                        t("Chat Support start call with ") + this.props.phoneNumber
                                    }
                                    data-patient-app-click="patient_app_chat_phone_number_btn"
                                    id="header_phone_number"
                                    role="link"
                                    onKeyDown={(e) =>
                                        e.key === " " || e.key === "Spacebar"
                                            ? (window.location.href = phoneNumberHref)
                                            : null
                                    }
                                >
                                    <span className="header--phone-icon-container">
                                        <svg
                                            className="header--phone-icon"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="20"
                                            viewBox="0 0 18 20"
                                        >
                                            <g fill="none" fill-rule="evenodd">
                                                <path
                                                    fill="#FFF"
                                                    fill-opacity="0"
                                                    d="M0 0H20V20H0z"
                                                    transform="translate(-1)"
                                                />
                                                <path
                                                    stroke="#3667E9"
                                                    stroke-width="1.8"
                                                    d="M11.217 14.244c1.299-1.1 2.103-1.417 4.162-.38 2.306 1.16 2.9 2.743-.51 4.575C7.791 18.74.66 9.558 2.908 3.039 5.662.361 7.066 1.352 7.58 3.822c.46 2.207-.077 2.87-1.524 3.778.542 2.668 2.666 5.402 5.16 6.643h0z"
                                                    transform="translate(-1)"
                                                />
                                            </g>
                                        </svg>
                                    </span>{" "}
                                    {this.props.phoneNumber}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const translatedComponent: React.AbstractComponent<OwnProps> = withTranslation()(Header);
export default translatedComponent;
